<template>
  <div>
    <Header></Header>
    <hr />
    <div class="d-flex flex-column ml-4 mt-3">
      <h2>Please read instructions carefully</h2>
      <div>
        <h3>General Instructions:</h3>
        <ul>
          <li>
            The total duration of the examination is {{ duration }} minutes.
          </li>
          <li>The maximum marks are {{ total }} marks</li>
          <li>There is no negative marking.</li>
          <li>
            <strong>The assessment consists of objective, short subjective and long subjective questions which need to be typed.</strong>
          </li>
          <li>
            After starting the assessment will end once you submit or time expires.
          </li>
          <li>
            <strong>If you wish to leave the test for any reason, you may press home button at end of the test. This will let you leave without ending the test. Your progress is saved, however the timer will continue once the test is started. It is advised that you only start the test if you wish to complete it within the stipulated time frame.</strong>
          </li>
          <li>
            Marked for review does not change the attempt for the question. It is for the assesses’ reference. 
          </li>
          <li>
            You can navigate between questions and sections on the navigation bar.
          </li>
        </ul>
      </div>
      <div>
        <h3>Answering a Question:</h3>
        <ul>
          <li>
            To mark a question for review, click on the Mark for Review and Next
            button.
          </li>
          <li>
            To change answer to a question that has already been answered,
            select that question from the Question Palette and then follow the
            procedure for answering that type of question
          </li>
          <li>
            Note that ONLY questions for which answers are either saved or
            marked for review after answering, will be considered for
            evaluation.
          </li>
          <li>Your responses are only recorded if you click ‘Next’ button after answering.</li>
        </ul>
      </div>
      <div>
        <h3>Submitting Test:</h3>
        <ul>
          <li>
            You may submit the assessment once you have completed the assessment on the last page by ‘submitting’ or by clicking ‘End Test’ on the navigation bar. Note clicking ‘Home’ button does not end the test.
          </li>
          
        </ul>
      </div>
      <h3>Legends:</h3>
      <div class="legends-container">
        <div class="single-legend">
          <div class="circle red"></div>
          <p><b>Not Attempted and marked For review</b></p>
        </div>
        <div class="single-legend">
          <div class="circle green"></div>
          <p><b>Attempted</b></p>
        </div>
        <div class="single-legend">
          <div class="circle white"></div>
          <p><b>Not Attempted</b></p>
        </div>
        <div class="single-legend">
          <div class="circle yellow"></div>

          <p><b>Attempted and marked for review</b></p>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="btn btn-danger mx-2 my-2 px-4" @click="goBack">Back</div>
        <div class="btn btn-success mx-2 my-2 px-4" @click="giveTest">Next</div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import store from "@/store";
import { getTest } from "@/Utils/global";

export default {
  name: "Instructions",
  components: { Header },
  props: ["test_id", "page_id"],
  data() {
    return {
      duration: 0,
      total: 0,
      title: ""
    };
  },
  async mounted() {
    //proceed data related to test
    // like duration // intructions  //number of questions and all
    let data = await getTest(this.page_id, this.test_id);
    data = data.data();
    this.duration = data.duration;
    this.title = data.title;
    this.total = data.total;
    store.state.loading = false;
  },
  methods: {
    giveTest() {
      this.$router.push({
        name: "Quiz",
        params: {
          id: this.test_id,
          page_id: this.page_id
        }
      });
    },
    goBack() {
      this.$router.push({
        name: "TestNotGiven",
        params: {
          page_id: this.page_id
        }
      });
    }
  }
};
</script>

<style scoped>
h3 {
  font-weight: bold;
}

.legends-container {
  /*display: flex;*/
}

.circle {
  border-radius: 50%;
  margin-right: 10px;
  width: 32px;
  height: 32px;
  background-color: red;
  text-align: center;
  border: solid black 1px;
  padding-top: 10px;
}
.single-legend {
  display: flex;
}

.circle > p {
  margin: 0 auto;
  text-align: center;
}

.red {
  margin-left: 0;
  background-color: red;
}

.green {
  background-color: green;
}

.white {
  background-color: white;

  padding-top: 0;
}

.yellow {
  background-color: yellow;
}
</style>
